<template>
  <div class="news-detail">
    <div class="title">
      {{ newsDetail.title }}
    </div>
    <div class="assist">
      <span>{{ isNull(newsDetail.province) + " " + isNull(newsDetail.city) + " " + isNull(newsDetail.district) }}</span>
      <span>{{ category }}</span>
      <span>{{ baseName }}</span>
      <span>{{ newsDetail.last_update_time }}</span>
    </div>
    <div class="av">
      <div class="audio">
        <img v-for="item in resource" :key="item.id"
             @click="openResourceDialog(item)"
             :src="checkResouceType(item)" alt=""/>

      </div>
    </div>
    <div v-show="resourceDialogVisiable" class="avVideo">
      <video v-if="resourceType"
             id="audioPlayer"
             autoplay="autoPlay"
             height="50"
             controls
             :src="resourceUrl1"/>
      <video v-if="!resourceType"
             id="videoPlayer"
             autoplay="autoplay"
             controls
             :src="resourceUrl2"/>
    </div>
    <div class="w-e-text-container detail">
      <div class="w-e-text" v-html="newsDetail.content_detail"></div>
    </div>
  </div>
</template>
<script>
  import * as ResourceApi from "../../api/api/Resources"
  import {AXIOS_BASE_URL} from "@/api/config";
  import  videojs from "video.js";


  export default {
    name: "newsDetail",
    components: {},
    data() {
      return {
        newsDetail: {
          title: "",
          issue: '',
          address: '',
          newsType: '',
          base: '',
          audio: [],
          video: [],
          content: "",
        },
        category: "",
        baseName: "",
        resourceUrl1: "",
        resourceUrl2: "",
        resourceType: "01",
        resource: [],
        showAudio: true,
        showVideo: true,
        resourceDialogVisiable: false,
        audioPlayer:null,
        videoPlayer:null
      }
    },
    methods: {
      // --- 数据处理 ---
      /**
       * 字符串中的Null转换为空字符串
       * @param val
       * @return {string|*}
       */
      isNull(val) {
        return val === undefined ? "" : val === null ? "" : val;
      },
      /**
       * 判断资源类型
       * @param val val.type 01 - 音频 02 - 视频
       * @return {*} 对应图标路径
       */
      checkResouceType(val) {
        return val.type === "01" ?
          require("../../assets/static/imgs/newsAudio.png") :
          require("../../assets/static/imgs/newsVideo.png")
      },
      // --- 事件 ---
      /**
       * 打开资源弹窗
       * @param val 资源信息
       */
      openResourceDialog(val) {
        this.resourceDialogVisiable = true;
        this.resourceType = (val.type === "01" ? true : false);
        if (this.resourceType) {
          this.resourceUrl1 = AXIOS_BASE_URL + val.path;
        } else {
          this.resourceUrl2 = AXIOS_BASE_URL + val.path;
        }
        console.log(val);
      },
      /**
       * 根据发布id获取文章信息和资源
       * @constructor
       */
      GetArticleForId() {
        const pd = {
          id: this.$route.query.id
        };
        ResourceApi.GetArticleForId(pd)
          .then(res => {
            if (res.data.code === 200) {
              console.log("详情", res);
              this.newsDetail = res.data.data.article;
              this.resource = res.data.data.resource;
              this.category = res.data.data.categoryName;
              this.baseName = res.data.data.baseName
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err);
            this.$message.error("获取文章失败")
          })
      },


    },
    mounted() {
      //console.log("videojs",videojs);
      videojs;
      document.title = "乡土文化详情";
      this.GetArticleForId()
      this.audioPlayer=document.getElementById("audioPlayer");
      this.videoPlayer=document.getElementById("audioPlayer");
      console.log(this.audioPlayer,this.videoPlayer);
    },

  }
</script>

<style scoped lang="less">
  .news-detail {
    padding: 0 10px;

    /*video {*/
    /*  width: 70vw;*/
    /*  object-fit: fill;*/
    /*}*/

    .audio {
      flex: 1 1 auto;
      margin: 0 2px;
      text-align: right;
    }

    .title {
      font-size: 24px;
      text-align: center;
      margin: 10px 0;
      font-weight: bold;
    }

    .assist {
      margin: 10px 0;
      color: rgb(170, 170, 170);
      display: flex;
      justify-content: space-between;
      font-size: 10px;
    }

    .av {
      display: flex;
      justify-content: flex-start;

      img {
        width: 26px;
        height: 26px;
      }
    }

    .avVideo {
      width: 100%;

      video {
        width: 100%;
        object-fit: fill;
      }
    }

    .audioPlay {
      resize: both;

      height: 50px;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .videoPlay {
      resize: both;

      video {
        width: 100%;
      }
    }

    .detail {
      ::v-deep(.w-e-text) {
        img {
          max-width: 100%;
        }
      }
    }
  }
</style>

<style lang="less">

</style>